import styles from './Footer.module.css';
import { P, TextLink } from '@ovotech/nebula';

const CURRENT_YEAR = new Date().getFullYear();
export const Footer = () => {
	return (
		<div className={styles.container}>
			<div className={styles.center}>
				<P>© {CURRENT_YEAR} OVO Energy Limited </P>
				<div className={styles.links}>
					<TextLink href="https://www.ovoenergy.com/terms">
						Terms & Conditions
					</TextLink>
					<TextLink href="https://www.ovoenergy.com/privacy-policy/">
						Privacy policy{' '}
					</TextLink>
					<TextLink href="https://www.ovoenergy.com/ovo-answers">
						Help Centre
					</TextLink>
				</div>
			</div>
		</div>
	);
};
