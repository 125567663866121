import { CTALink, Margin, Stack, TextField } from '@ovotech/nebula';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import stylesLink from '../../components/common/Link.module.css';
import { MemberDetails } from './MemberDetailsPage';
import { checkForEmptyString } from './ValidationUtils';

export const GivenName = ({
	details,
	setDetails,
	nextPath,
}: {
	details: MemberDetails;
	setDetails: React.Dispatch<React.SetStateAction<MemberDetails>>;
	nextPath: string;
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		ref.current?.focus();
	}, []);

	function onChange(value: string) {
		const err = checkForEmptyString(value);
		setDetails({ ...details, givenName: { val: value, error: err } });
	}

	return (
		<Stack spaceBetween={4}>
			<TextField
				id="givenName"
				label="What is your name?"
				fullWidth="always"
				error={details.givenName.error}
				value={details.givenName.val}
				ref={ref}
				onChange={(e: React.ChangeEvent<any>) => onChange(e.target.value)}
			/>
			<div className="flex-row--vertically-centred">
				<Margin right={3}>
					<CTALink
						className={stylesLink['cta-link']}
						aria-disabled={!!details.givenName.error}
						onClick={(e: any) => details.givenName.error && e.preventDefault()}
						as={Link}
						to={nextPath}
					>
						Continue
					</CTALink>
				</Margin>
			</div>
		</Stack>
	);
};
