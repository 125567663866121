import { OverridedMixpanel } from 'mixpanel-browser';
import { CheckedFields } from '../pages/MemberDetailsPage/MemberDetailsForm';
import { ReactSDKClient as OptimizelyClient } from '@optimizely/react-sdk';

const events = {
	PAGE_VIEW: `viewed page`,
	BUTTON_CLICK: `clicked button`,
	ACCOUNT_ID_MODAL_CLICK: `clicked account_id modal`,
	SHOWN_ERROR: `shown error message`,
} as const;

export const pageNames = {
	GET_STARTED: 'get_started',
	CHECK_INBOX: 'time_to_check_inbox',
	MORE_INFO: 'we_need_more_info',
	FINAL_BILL_PROMPT: 'final_bill_prompt',
	RESET_PASSWORD: 'reset_password',
} as const;

type pageName = typeof pageNames[keyof typeof pageNames];

export const buttonNames = {
	SUBMIT_EMAIL: 'submit_email_address',
	SUBMIT_INFO: 'submit_info',
	CONTACT_US: 'give_us_a_call',
	FINAL_BILL_PROMPT: 'final_bill_prompt',
} as const;

type buttonName = typeof buttonNames[keyof typeof buttonNames];

export const errorNames = {
	WRONG_DETAILS: 'wrong_details',
	MISSING_DETAILS: 'missing_details',
};

type errorName = typeof errorNames[keyof typeof errorNames];

const prefix = 'PWR -';

export interface AnalyticsService {
	trackPageView: (page: pageName) => void;
	trackButtonClick: (button: buttonName) => void;
	trackAccountIdModalClicked: () => void;
	trackErrorShown: (error: errorName, details: CheckedFields) => void;
	canTrack: () => boolean;
	/**
	 * Associate user ID with Mixpanel generated distinct_id.
	 *
	 * @param userEmail the user email
	 */
	mixpanelAlias: (userEmail: string) => void;
	/**
	 * Use original Mixpanel ID, associated with the user by calling the 'alias(userEmail)' function.
	 * Set new user for Optimizely client.
	 *
	 * @param userEmail the user email
	 */
	identify(userEmail: string): void;
	getId: () => string;
	getOptimizelyId: () => string;
}

interface LastTouchUTMParams {
	[key: string]: string | undefined;
	'utm_source [last touch]'?: string;
	'utm_campaign [last touch]'?: string;
	'utm_term [last_touch]'?: string;
	'utm_medium [last touch]'?: string;
}

export function createAnalyticsService({
	mixpanelClient,
	optimizelyClient,
}: {
	mixpanelClient: OverridedMixpanel;
	optimizelyClient: OptimizelyClient;
}): AnalyticsService {
	function registerLastTouchUtmParams() {
		const UTMParamNames = [
			'utm_source',
			'utm_campaign',
			'utm_term',
			'utm_medium',
		];

		const lastTouch: LastTouchUTMParams = {};

		const search = new URLSearchParams(window.location.search);

		UTMParamNames.forEach((name) => {
			if (search.has(name)) {
				const value = search.get(name);
				if (value && value.length > 0) {
					lastTouch[`${name} [last touch]`] = value;
				}
			}
		});

		mixpanelClient.register(lastTouch);
	}

	let eventQueue: Array<{ eventName: string; props?: Record<string, any> }> =
		[];
	let optimizelyQueue: Array<string> = [];

	// Initialising to an empty string causes errors in the client
	// The token should only be missing in development so setting it to an empty string is fine here
	mixpanelClient.init(process.env.REACT_APP_MIXPANEL_TOKEN || ' ', {
		opt_out_tracking_by_default: true,
	});

	const distinctId = mixpanelClient.get_distinct_id();
	let optimizelyId = distinctId;

	registerLastTouchUtmParams();

	//Check whether the user has opted out of data tracking and cookies/localstorage
	function hasOptedOutTracking() {
		return mixpanelClient.has_opted_out_tracking();
	}

	function track(event: string, props?: Record<string, any>): void {
		const eventName = `${prefix} ${event}`;

		if (hasOptedOutTracking()) {
			// If they haven't accepted or rejected the cookie banner yet queue up the events in case they will do later.
			if (props) {
				props.time = Date.now();
			}
			eventQueue.push({ eventName, props });

			return;
		}

		mixpanelClient.track(eventName, props);
		//Execute events from the queue
		if (eventQueue.length > 0) {
			eventQueue.forEach((e) => mixpanelClient.track(e.eventName, e.props));
			eventQueue = [];
		}
	}

	function toSnakeCase(input: string): string {
		return input.replace(/ /g, '_');
	}

	function trackOptimizely(event: string): void {
		const eventName = toSnakeCase(event);

		if (hasOptedOutTracking()) {
			optimizelyQueue.push(eventName);

			return;
		}

		optimizelyClient.track(eventName, optimizelyId);
		//Execute events from the queue
		if (optimizelyQueue.length > 0) {
			optimizelyQueue.forEach((e) => optimizelyClient.track(e, optimizelyId));
			optimizelyQueue = [];
		}
	}

	return {
		trackPageView(page) {
			track(events.PAGE_VIEW, {
				page_name: page,
			});

			trackOptimizely(`${events.PAGE_VIEW}_${page}`);
		},
		trackButtonClick(button) {
			track(events.BUTTON_CLICK, { button_id: button });
			trackOptimizely(`${events.BUTTON_CLICK}_${button}`);
		},
		trackAccountIdModalClicked() {
			track(events.ACCOUNT_ID_MODAL_CLICK);
			trackOptimizely(events.ACCOUNT_ID_MODAL_CLICK);
		},
		trackErrorShown(error, details) {
			track(events.SHOWN_ERROR, {
				error_type: error,
				...details,
			});
		},
		canTrack() {
			return !hasOptedOutTracking();
		},
		mixpanelAlias(userEmail) {
			if (!userEmail) {
				return;
			}
			const emailHash = hash(userEmail);
			mixpanelClient.alias(emailHash);
		},
		identify(userEmail) {
			if (!userEmail) {
				return;
			}
			//use user's email as id to ensure consistency
			const emailHash = hash(userEmail);
			mixpanelClient.identify(emailHash);
			optimizelyId = emailHash;
			optimizelyClient.setUser({ id: emailHash });
		},
		getId() {
			return distinctId;
		},
		getOptimizelyId() {
			return optimizelyId;
		},
	};
}

/**
 * Simple high quality 53-bit hash function.
 *
 * @param {string} str - the string to get hash function
 * @param {number} seed - the seed number
 * @returns {string} a 53-bit hash
 */
function hash(str: string, seed = 0): string {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed;

	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}

	h1 =
		Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
		Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 =
		Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
		Math.imul(h1 ^ (h1 >>> 13), 3266489909);

	return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
}
