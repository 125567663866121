import { CTALink, DateField, Margin, Stack, TextLink } from '@ovotech/nebula';
import { DateFormat } from '@ovotech/nebula/dist/components/DateField/DateField';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../components/common/Button.module.css';
import stylesLink from '../../components/common/Link.module.css';
import { MemberDetails } from './MemberDetailsPage';
import { checkForEmptyDate, validateDate } from './ValidationUtils';

export const BirthDate = ({
	details,
	setDetails,
	prevPath,
	nextPath,
}: {
	details: MemberDetails;
	setDetails: React.Dispatch<React.SetStateAction<MemberDetails>>;
	prevPath: string;
	nextPath: string;
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		ref.current?.focus();
	}, []);

	function onChange(date: DateFormat) {
		const err = checkForEmptyDate(date) || validateDate(date);
		setDetails({ ...details, birthdate: { val: date, error: err } });
	}

	return (
		<Stack spaceBetween={4}>
			<div>
				&lt;{' '}
				<TextLink as={Link} to={prevPath} className={styles['btn-link']}>
					Back
				</TextLink>
			</div>
			<DateField
				id="birthDate"
				label="What is your day of birth?"
				fullWidth="always"
				ref={ref}
				error={details.birthdate.error}
				value={details.birthdate.val}
				onChange={(date: DateFormat) => onChange(date)}
			/>
			<div className="flex-row--vertically-centred">
				<Margin right={3}>
					<CTALink
						className={stylesLink['cta-link']}
						aria-disabled={!!details.birthdate.error}
						onClick={(e: any) => details.birthdate.error && e.preventDefault()}
						as={Link}
						to={nextPath}
					>
						Continue
					</CTALink>
				</Margin>
			</div>
		</Stack>
	);
};
