import { CTALink, Margin, Stack, TextField, TextLink } from '@ovotech/nebula';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../components/common/Button.module.css';
import stylesLink from '../../components/common/Link.module.css';
import { MemberDetails } from './MemberDetailsPage';
import { checkForEmptyString } from './ValidationUtils';

export const FamilyName = ({
	details,
	setDetails,
	prevPath,
	nextPath,
}: {
	details: MemberDetails;
	setDetails: React.Dispatch<React.SetStateAction<MemberDetails>>;
	prevPath: string;
	nextPath: string;
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		ref.current?.focus();
	}, []);

	function onChange(value: string) {
		const err = checkForEmptyString(value);
		setDetails({ ...details, familyName: { val: value, error: err } });
	}

	return (
		<Stack spaceBetween={4}>
			<div>
				&lt;{' '}
				<TextLink as={Link} to={prevPath} className={styles['btn-link']}>
					Back
				</TextLink>
			</div>
			<TextField
				id="familyName"
				label="What is your last name?"
				fullWidth="always"
				ref={ref}
				error={details.familyName.error}
				value={details.familyName.val}
				onChange={(e: React.ChangeEvent<any>) => onChange(e.target.value)}
			/>
			<div className="flex-row--vertically-centred">
				<Margin right={3}>
					<CTALink
						className={stylesLink['cta-link']}
						aria-disabled={!!details.familyName.error}
						onClick={(e: any) => details.familyName.error && e.preventDefault()}
						as={Link}
						to={nextPath}
					>
						Continue
					</CTALink>
				</Margin>
			</div>
		</Stack>
	);
};
