import { Card, CTALink, ErrorNotification, P } from '@ovotech/nebula';
import memberDetailsPageStyles from '../MemberDetailsPage/MemberDetailsPage.module.css';
import { Loading } from '../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { matchesState } from 'xstate';
import { usePageContext } from '../../components/Page';
import { useExperiment } from '@optimizely/react-sdk';
import { Redirect } from 'react-router';

export function ConfirmEmailPage({ token }: { token: string }) {
	const { apiClient, analyticsService, setUserData } = usePageContext();
	const [emailConfirmed, setEmailConfirmed] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [loading, setLoading] = useState(true);
	const [variation] = useExperiment('final_bill_prompt_experiment');
	const [showFinalBillPrompt, setShowFinalBillPrompt] = useState(false);

	useEffect(() => {
		const confirmEmail = async () => {
			try {
				const response = await apiClient.confirmEmail({ token });
				if (response.ok) {
					const json = await response.json();
					if (matchesState('email_confirmed', json.state)) {
						setEmailConfirmed(true);
						setUserData({ token: token, email: json.email });
						setShowFinalBillPrompt(
							analyticsService.canTrack() && variation === 'on'
						);
					} else {
						if (json.state === 'token_expired') {
							setErrorMessage('Your link has expired.');
						} else {
							setErrorMessage('');
						}
					}
				}
			} catch (_) {
				setErrorMessage('');
			} finally {
				setLoading(false);
			}
		};
		confirmEmail();
	}, [token, apiClient, variation, analyticsService, setUserData]);
	return (
		<>
			{loading ? (
				<Card className={memberDetailsPageStyles.loader}>
					<Loading size="md" />
				</Card>
			) : emailConfirmed ? (
				showFinalBillPrompt ? (
					<Redirect
						push
						to={{
							pathname: '/final_bill_prompt',
							search: window.location.search,
						}}
					/>
				) : (
					<Redirect
						push
						to={{ pathname: '/details_form', search: window.location.search }}
					/>
				)
			) : (
				<ErrorNotification id="confirmation-fail-notification">
					<P>{errorMessage}</P>
					<CTALink as={Link} to="/confirm-email">
						Try again
					</CTALink>
				</ErrorNotification>
			)}
		</>
	);
}
