import { ErrorNotification, P } from '@ovotech/nebula';
import React, { useEffect, useRef, useState } from 'react';
import { matchesState } from 'xstate';
import { ContactUsButton } from '../../components/ContactUsButton/ContactUsButton';
import {
	doesDataHaveErrorArray,
	doesDataHaveState,
	HttpResponse,
} from './ApiResponseUtils';

export function PageError({ httpResponse }: { httpResponse: HttpResponse }) {
	const ref = useRef<null | HTMLDivElement>(null);
	const [errorMessage, setErrorMessage] = useState<null | JSX.Element>(null);

	useEffect(() => {
		if (httpResponse.status === 'error' || httpResponse.status === 'fetched') {
			setErrorMessage(getErrorMessage(httpResponse));
		}
	}, [httpResponse]);

	useEffect(() => {
		ref.current?.focus();
	}, [errorMessage]);

	if (!errorMessage) {
		return null;
	}

	return (
		<ErrorNotification id="error-notification" ref={ref}>
			{errorMessage}
		</ErrorNotification>
	);
}

function getErrorMessage(
	httpResponse: Extract<HttpResponse, { status: 'fetched' | 'error' }>
): JSX.Element {
	const genericMessage = (
		<P>
			Something went wrong, please try again later. If that still doesn't work,{' '}
			<ContactUsButton />
		</P>
	);

	if (httpResponse.status === 'error') {
		return genericMessage;
	}

	if (
		httpResponse.httpStatus === 400 &&
		doesDataHaveErrorArray(httpResponse.data) &&
		// @ts-ignore
		httpResponse.data.errors.includes('Invalid token')
	) {
		return <P>Your account activation link is not valid</P>;
	}

	if (
		httpResponse.httpStatus !== 200 ||
		!doesDataHaveState(httpResponse.data)
	) {
		return genericMessage;
	}

	if (
		matchesState(
			'email_confirmed.details.invalid_details',
			// @ts-ignore
			httpResponse.data.state
		)
	) {
		return (
			<P>
				Sorry! Some of your details don’t match your account information. Please
				check and try again. If that still doesn't work, <ContactUsButton />
			</P>
		);
	}

	// @ts-ignore
	if (httpResponse.data.state === 'token_expired') {
		return <P>Your account activation link has expired</P>;
	}

	return genericMessage;
}
