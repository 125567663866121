import {
	Card,
	EmailField,
	ErrorNotification,
	Heading1,
	Margin,
	P,
	PrimaryCTAButton,
	Stack,
} from '@ovotech/nebula';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Loading } from '../../components/Loading/Loading';
import { usePageContext } from '../../components/Page';
import { buttonNames } from '../../services/analytics';

interface EmailFormProps {
	setSubmitted: (submitted: boolean) => void;
}

export function EmailForm({ setSubmitted }: EmailFormProps) {
	const { analyticsService, apiClient } = usePageContext();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const formik = useFormik({
		initialValues: {
			email: '',
			emailConfirm: '',
		},
		validate: (values) => {
			const errors: { email?: string; emailConfirm?: string } = {};

			if (!values.email) {
				errors.email = 'Enter a valid email address';
			}

			if (values.emailConfirm !== values.email) {
				errors.emailConfirm = 'Email address does not match';
			}

			return errors;
		},
		validateOnBlur: true,
		validateOnChange: false,
		onSubmit: async (values) => {
			analyticsService.trackButtonClick(buttonNames.SUBMIT_EMAIL);
			const email = values.email.toLowerCase();

			try {
				setLoading(true);
				const response = await apiClient.checkEmail({
					email: email,
					mixpanelDistinctId: analyticsService.canTrack()
						? analyticsService.getId()
						: null,
				});

				if (response.ok) {
					analyticsService.mixpanelAlias(email);
					setSubmitted(true);
				} else if (response.status === 400) {
					setError('Email address is invalid');
				} else {
					setError('An error occurred, try again later');
				}
			} catch (error: any) {
				setError('Check your connection and try again');
			} finally {
				setLoading(false);
			}
		},
	});

	return (
		<Stack spaceBetween={2}>
			<Heading1 className="heading">Let's get started!</Heading1>
			<Card>
				<P>
					Just enter the email address that's linked to your OVO account. We'll
					email you a special link to set your password.
				</P>
			</Card>

			{error ? (
				<ErrorNotification id="error-notification">
					<P>{error}</P>
				</ErrorNotification>
			) : null}

			<Card>
				<form onSubmit={formik.handleSubmit} noValidate>
					<Stack spaceBetween={4}>
						<EmailField
							name="email"
							id="email"
							label="Email address"
							error={formik.errors.email}
							fullWidth="always"
							onChange={formik.handleChange}
							value={formik.values.email}
						/>

						<EmailField
							id="emailConfirm"
							name="emailConfirm"
							label="Confirm email address"
							error={formik.errors.emailConfirm}
							fullWidth="always"
							onChange={formik.handleChange}
							value={formik.values.emailConfirm}
						/>

						<div className="flex-row--vertically-centred">
							<Margin right={3}>
								<PrimaryCTAButton disabled={loading} type="submit">
									Submit
								</PrimaryCTAButton>{' '}
							</Margin>
							{loading ? <Loading /> : undefined}
						</div>
					</Stack>
				</form>
			</Card>
		</Stack>
	);
}
