import React, { useEffect } from 'react';
import { Card, CTALink, Heading1, P, TextLink } from '@ovotech/nebula';
import { ReactComponent as LetterGraphic } from '../images/letter-graphic.svg';
import { usePageContext } from './Page';
import { buttonNames, pageNames } from '../services/analytics';
import { Link, useRouteMatch } from 'react-router-dom';

export const FinalBillPrompt = () => {
	const { analyticsService } = usePageContext();
	const { url } = useRouteMatch();

	useEffect(() => {
		analyticsService.trackPageView(pageNames.FINAL_BILL_PROMPT);
	}, [analyticsService]);

	function trackClick() {
		analyticsService.trackButtonClick(buttonNames.FINAL_BILL_PROMPT);
		return true;
	}

	return (
		<Card>
			<Heading1>Let's get started</Heading1>
			<P>
				To make your switch as smooth as possible, please make sure you have
				your final bill email or letter from SSE to hand. It includes your OVO
				Energy account number. It should look something like this:
			</P>

			<LetterGraphic />

			<P>
				If you can't find your final bill, please give us a call on{' '}
				<TextLink href="tel:03303035063">0330 303 5063</TextLink>. Our opening
				hours: Monday to Friday 8:00am - 6:00pm.
			</P>

			<CTALink
				as={Link}
				fullWidth="always"
				to={{
					pathname: url.replace('final_bill_prompt', 'details_form'),
					search: window.location.search,
				}}
				onClick={trackClick}
			>
				Let's get started!
			</CTALink>
		</Card>
	);
};
