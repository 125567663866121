import { P, ErrorNotification } from '@ovotech/nebula';
import styles from './Page.module.css';

export function RetryLimitReachedPage() {
	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<ErrorNotification id="error-notification">
					<P>
						You’ve reached the maximum number of retries. To activate your
						account, give us a call on
						<br /> 0330 303 5063 (Monday–Friday 8am–6pm).
					</P>
				</ErrorNotification>
			</div>
		</div>
	);
}
