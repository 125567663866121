import React, { useState } from 'react';
import { P, Modal, TextLink, Heading3, Stack, Margin } from '@ovotech/nebula';
import { usePageContext } from '../Page';
import buttonStyles from '../common/Button.module.css';
import letterStyles from './FindAccountNumberLink.module.css';
import { ReactComponent as Letter } from '../../images/letter-graphic.svg';
export function FindAccountNumberLink() {
	const [showModal, setShowModal] = useState<boolean>(false);
	const { analyticsService } = usePageContext();

	function onClick() {
		analyticsService.trackAccountIdModalClicked();
		setShowModal(true);
	}

	return (
		<>
			<TextLink
				as="button"
				className={buttonStyles['btn-link']}
				onClick={onClick}
				type="button"
			>
				Can't find your account number?
			</TextLink>
			<Modal title="" isOpen={showModal} onClose={() => setShowModal(false)}>
				<Margin top={10}>
					<Stack spaceBetween={2}>
						<Heading3>How to find your OVO Energy account number</Heading3>

						<P>
							Any letters or emails that we've sent you will have your OVO
							Energy account number.
						</P>

						<Heading3>Joining us from SSE?</Heading3>

						<P>
							You'll find your new OVO account on your final bill letter from
							SSE. It should look something like this:
						</P>

						<P>
							<Letter className={letterStyles['letter']} />
						</P>

						<P>
							Can’t find any letters or emails? Contact us on{' '}
							<TextLink href="tel:03303035063">0330 303 5063.</TextLink>
						</P>
						<P>Our normal opening hours are Mon-Fri 9am-5pm</P>
					</Stack>
				</Margin>
			</Modal>
		</>
	);
}
