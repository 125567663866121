import { Card, Heading1, P, Stack } from '@ovotech/nebula';
import { DateFormat } from '@ovotech/nebula/dist/components/DateField/DateField';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Loading } from '../../components/Loading/Loading';
import { usePageContext } from '../../components/Page';
import { buttonNames, pageNames } from '../../services/analytics';
import { AccountID } from './AccountID';
import {
	doesDataHaveState,
	doesDataIncludePasswordResetUrl,
	HttpResponse,
} from './ApiResponseUtils';
import { BirthDate } from './BirthDate';
import { FamilyName } from './FamilyName';
import { GivenName } from './GivenName';
import { PageError } from './PageError';
import { Postcode } from './Postcode';
import { formatDate, MANDATORY_FIELD_ERROR } from './ValidationUtils';

export const GIVEN_NAME_PATH = '/details_form';
export const FAMILY_NAME_PATH = '/details_form/family_name';
export const POSTCODE_PATH = '/details_form/postcode';
export const BIRTHDATE_PATH = '/details_form/birthdate';
export const ACCOUNT_ID_PATH = '/details_form/account_id';

/**
 * Member details, collected from all input fields.
 */
export interface MemberDetails {
	givenName: {
		val: string;
		error: string;
	};
	familyName: {
		val: string;
		error: string;
	};
	postCode: {
		val: string;
		error: string;
	};
	birthdate: {
		val: DateFormat;
		error: string;
	};
	accountId: {
		val: string;
		error: string;
	};
}

export const MemberDetailsPage = () => {
	const { apiClient, userData } = usePageContext();
	const [response, setResponse] = useState<HttpResponse>({ status: 'idle' });
	const { analyticsService } = usePageContext();
	const [details, setDetails] = useState<MemberDetails>({
		givenName: { val: '', error: MANDATORY_FIELD_ERROR },
		familyName: { val: '', error: MANDATORY_FIELD_ERROR },
		postCode: { val: '', error: MANDATORY_FIELD_ERROR },
		birthdate: {
			val: { day: '', month: '', year: '' },
			error: MANDATORY_FIELD_ERROR,
		},
		accountId: { val: '', error: MANDATORY_FIELD_ERROR },
	});

	const isLoading = useMemo(() => {
		return response.status === 'loading';
	}, [response]);

	useEffect(() => {
		analyticsService.trackPageView(pageNames.MORE_INFO);
	}, [analyticsService]);

	async function onSubmit() {
		setResponse({ status: 'loading' });
		analyticsService.trackButtonClick(buttonNames.SUBMIT_INFO);
		try {
			const response = await apiClient.checkDetails({
				memberDetails: {
					givenName: details.givenName.val,
					familyName: details.familyName.val,
					postCode: details.postCode.val,
					birthdate: formatDate(details.birthdate.val),
					accountId: details.accountId.val.replace(/ /g, ''), //strip account ID
				},
				token: userData.token,
			});

			setResponse({
				status: 'fetched',
				httpStatus: response.status,
				data: await response.json(),
			});
		} catch (error) {
			setResponse({ status: 'error' });
		}
	}

	// The user was matched, redirect to Password reset page
	if (
		response.status === 'fetched' &&
		doesDataIncludePasswordResetUrl(response.data)
	) {
		analyticsService.trackPageView(pageNames.RESET_PASSWORD);
		// @ts-ignore
		return <ExternalRedirect to={response.data.passwordResetUrl} />;
	}
	// Show Max (configured on server side, 50) check details attempts reached
	if (
		response.status === 'fetched' &&
		doesDataHaveState(response.data) &&
		// @ts-ignore
		response.data.state === 'check_attempt_limit_reached'
	) {
		return <Redirect push={false} to="/retry-limit-reached" />;
	}

	// Show user details card
	return (
		<Stack spaceBetween={2}>
			<Heading1 className="heading">We need more information from you</Heading1>
			<Card>
				<P>
					Setting up your new online account? We just need a few more details
					from you. Please make sure they match your account information.
				</P>
			</Card>
			<PageError httpResponse={response} />

			<Card>
				<Switch>
					<Route exact path={GIVEN_NAME_PATH}>
						<GivenName
							details={details}
							setDetails={setDetails}
							nextPath={FAMILY_NAME_PATH}
						/>
					</Route>
					<Route path={FAMILY_NAME_PATH}>
						<FamilyName
							details={details}
							setDetails={setDetails}
							prevPath={GIVEN_NAME_PATH}
							nextPath={POSTCODE_PATH}
						/>
					</Route>
					<Route path={POSTCODE_PATH}>
						<Postcode
							details={details}
							setDetails={setDetails}
							prevPath={FAMILY_NAME_PATH}
							nextPath={BIRTHDATE_PATH}
						/>
					</Route>
					<Route path={BIRTHDATE_PATH}>
						<BirthDate
							details={details}
							setDetails={setDetails}
							prevPath={POSTCODE_PATH}
							nextPath={ACCOUNT_ID_PATH}
						/>
					</Route>
					<Route path={ACCOUNT_ID_PATH}>
						<AccountID
							details={details}
							setDetails={setDetails}
							prevPath={BIRTHDATE_PATH}
							isLoading={isLoading}
							submitCallback={onSubmit}
						/>
					</Route>
				</Switch>
			</Card>
		</Stack>
	);
};

function ExternalRedirect({ to }: { to: string }) {
	useEffect(() => {
		window.location.assign(to);
	}, [to]);

	// Spinner will show while the browser keeps the current page around (this can be quite a while on
	// slower connections)
	return <Loading />;
}
