import mixpanel from 'mixpanel-browser';
import {
	ReactNode,
	createContext,
	useContext,
	useState,
	Dispatch,
	useCallback,
	useEffect,
} from 'react';
import {
	AnalyticsService,
	createAnalyticsService,
} from '../services/analytics';
import { ApiClient, createApiClient } from '../services/api';
import {
	createInstance,
	OptimizelyProvider,
	setLogLevel,
} from '@optimizely/react-sdk';
import styles from '../pages/Page.module.css';

export interface UserData {
	readonly token: string | null;
	readonly email: string | null;
}

const EMPTY_USER_DATA = { token: null, email: null };

export const PageContext = createContext<{
	analyticsService: AnalyticsService | null;
	apiClient: ApiClient | null;
	userData: UserData;
	setUserData: Dispatch<UserData> | null;
}>({
	analyticsService: null,
	apiClient: null,
	userData: EMPTY_USER_DATA,
	setUserData: null,
});

export const StorageTokenPropName = 'token';
export const StorageEmailPropName = 'email';

export function usePageContext(): {
	analyticsService: AnalyticsService;
	apiClient: ApiClient;
	userData: UserData;
	setUserData: Dispatch<UserData>;
} {
	const { analyticsService, apiClient, userData, setUserData } =
		useContext(PageContext);

	if (analyticsService === null) {
		throw new Error('Analytics service is not defined');
	}

	if (apiClient === null) {
		throw new Error('API client is not defined');
	}

	if (setUserData === null) {
		throw new Error('setUserData is not defined');
	}

	return {
		analyticsService,
		apiClient,
		userData: userData,
		setUserData: setUserData,
	};
}

const optimizelyClient = createInstance({
	// @ts-ignore
	datafile: window.optimizelyDatafile,
});

setLogLevel(process.env.NODE_ENV === 'production' ? 'error' : 'debug');

const analyticsService = createAnalyticsService({
	mixpanelClient: mixpanel,
	optimizelyClient,
});

export const Page = ({ children }: { children: ReactNode }) => {
	const [userData, setUserDataState] = useState<UserData>(EMPTY_USER_DATA);

	// We do retrieve userData from local storage on page mount, assuming it was put there
	// using `setUserData` function defined below.
	// This fixes the missing user data issue when the user does refresh of `MemberDetailsPage`.
	useEffect(() => {
		const token = localStorage.getItem(StorageTokenPropName) ?? null;
		const email = localStorage.getItem(StorageEmailPropName) ?? null;

		if (email) {
			analyticsService.identify(email);
		}
		setUserDataState({ token, email });
	}, []);

	const setUserData = useCallback((userData: UserData) => {
		userData.token &&
			localStorage.setItem(StorageTokenPropName, userData.token);

		if (userData.email) {
			localStorage.setItem(StorageEmailPropName, userData.email);
			analyticsService.identify(userData.email);
		}
		setUserDataState(userData);
	}, []);

	return (
		<div className="ovoBranding">
			<div className="ovo-new-header">
				<div className="ovo-new-logo" />
			</div>
			<OptimizelyProvider
				optimizely={optimizelyClient}
				user={{ id: analyticsService.getOptimizelyId() }}
			>
				<PageContext.Provider
					value={{
						userData: userData,
						setUserData: setUserData,
						analyticsService,
						apiClient: createApiClient({
							fetchClient: fetch,
							apiHost: process.env.REACT_APP_API_ENDPOINT || '',
						}),
					}}
				>
					<div className="container">
						<div className={styles.container}>
							<div className={styles.wrapper}>{children}</div>
						</div>
					</div>
				</PageContext.Provider>
			</OptimizelyProvider>
		</div>
	);
};
