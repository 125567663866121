import { useEffect, useRef, useState } from 'react';
import {
	Card,
	DateField,
	ErrorNotification,
	Margin,
	P,
	PrimaryCTAButton,
	Stack,
	TextField,
} from '@ovotech/nebula';
import { useFormik } from 'formik';
import { Loading } from '../../components/Loading/Loading';
import { buttonNames, errorNames, pageNames } from '../../services/analytics';
import { usePageContext } from '../../components/Page';
import { Redirect } from 'react-router';
import { ContactUsButton } from '../../components/ContactUsButton/ContactUsButton';
import { FindAccountNumberLink } from '../../components/FindAccountNumberLink/FindAccountNumberLink';
import { matchesState } from 'xstate';

function ensureLeadingZero(value: string): string {
	return value.padStart(2, '0');
}

interface DayMonthYear {
	day: string;
	month: string;
	year: string;
}

function isValidDate(date: DayMonthYear): boolean {
	if (date.year.length !== 4) return false; // V8 will accept 2 digit years whereas gecko says its invalid
	return !isNaN(Number(new Date(formatDate(date))));
}

function formatDate({ day, month, year }: DayMonthYear): string {
	return `${year}-${ensureLeadingZero(month)}-${ensureLeadingZero(day)}`;
}

function isValidAccountId(accountId: string): boolean {
	if (!accountId) return false;
	return !isNaN(Number(accountId.replace(/ /g, '')));
}

function checkValue(
	value: string,
	validator: (input: string) => boolean = (_: string) => true
): CheckedFieldResult {
	if (value.length === 0) {
		return 'missing';
	} else {
		return validator(value) ? 'valid' : 'invalid';
	}
}

type CheckedFieldResult = 'valid' | 'invalid' | 'missing';
type FieldNames = keyof FormValues;
export type CheckedFields = Record<FieldNames, CheckedFieldResult>;

function checkBirthdateValue(date: DayMonthYear): CheckedFieldResult {
	if (!date.day || !date.month || !date.year) return 'missing';
	if (!isValidDate(date)) return 'invalid';
	return 'valid';
}

function checkFieldValues(values: FormValues) {
	return {
		givenName: checkValue(values.givenName),
		familyName: checkValue(values.familyName),
		postCode: checkValue(values.postCode),
		birthdate: checkBirthdateValue(values.birthdate),
		accountId: checkValue(values.accountId, isValidAccountId),
	};
}

function isMissing(input: CheckedFieldResult) {
	return input === 'missing';
}

function isInvalid(input: CheckedFieldResult) {
	return input === 'invalid';
}

function getAccountIdErrorMessage(
	validationResult: CheckedFields
): string | null {
	if (isMissing(validationResult.accountId)) {
		return 'Enter your OVO account number';
	}

	if (isInvalid(validationResult.accountId)) {
		return 'Enter numbers only, for example: 90001234';
	}

	return null;
}

function getBirthdateErrorMessage(
	birthdateValidationResult: CheckedFieldResult,
	birthdate: DayMonthYear
): string | null {
	if (isMissing(birthdateValidationResult)) {
		return 'Enter your date of birth';
	}

	if (birthdate.year.length !== 4) {
		return 'Year must be 4 digits (YYYY)';
	}

	if (!isValidDate(birthdate)) {
		return `${birthdate.day}-${birthdate.month}-${birthdate.year} is not a valid date`;
	}

	return null;
}

function getFieldErrors(
	validationResult: CheckedFields,
	values: FormValues
): Record<string, string> {
	let errors: Partial<Record<FieldNames, string>> = {};

	if (isMissing(validationResult.givenName)) {
		errors.givenName = 'Enter your first name';
	}

	if (isMissing(validationResult.familyName)) {
		errors.familyName = 'Enter your last name';
	}

	if (isMissing(validationResult.postCode)) {
		errors.postCode = 'Enter your postcode';
	}

	const dobError = getBirthdateErrorMessage(
		validationResult.birthdate,
		values.birthdate
	);

	if (dobError !== null) {
		errors.birthdate = dobError;
	}

	const accountIdError = getAccountIdErrorMessage(validationResult);
	if (accountIdError !== null) {
		errors.accountId = accountIdError;
	}

	return errors;
}

type FormResponse =
	| { status: 'idle' }
	| { status: 'loading' }
	| { status: 'fetched'; httpStatus: number; data: unknown }
	| { status: 'error' };

const isObject = (data: unknown): data is object =>
	typeof data === 'object' && data != null;

const doesDataIncludePasswordResetUrl = (
	data: unknown
): data is { passwordResetUrl: string } =>
	isObject(data) &&
	'passwordResetUrl' in data &&
	typeof (data as any).passwordResetUrl === 'string';

const doesDataHaveErrorArray = (data: unknown): data is { errors: string[] } =>
	isObject(data) && Array.isArray((data as any).errors);

const doesDataHaveState = (
	data: unknown
): data is {
	state: { email_confirmed: { details: string; reminders: string } } | string;
} => isObject(data) && 'state' in data;

interface FormValues {
	givenName: string;
	familyName: string;
	postCode: string;
	birthdate: DayMonthYear;
	accountId: string;
}

export const MemberDetailsForm = () => {
	const { analyticsService, apiClient, userData } = usePageContext();
	const [response, setResponse] = useState<FormResponse>({ status: 'idle' });
	const formik = useFormik({
		initialValues: {
			givenName: '',
			familyName: '',
			postCode: '',
			birthdate: { day: '', month: '', year: '' },
			accountId: '',
		},
		validate: (values: FormValues) => {
			const checkedFields = checkFieldValues(values);
			const errors = getFieldErrors(checkedFields, values);

			if (Object.values(errors).length > 0) {
				analyticsService.trackErrorShown(
					errorNames.MISSING_DETAILS,
					checkedFields
				);
			}

			return errors;
		},
		validateOnBlur: true,
		validateOnChange: false,
		onSubmit: async (values) => {
			setResponse({ status: 'loading' });
			analyticsService.trackButtonClick(buttonNames.SUBMIT_INFO);
			try {
				const response = await apiClient.checkDetails({
					memberDetails: {
						givenName: values.givenName,
						familyName: values.familyName,
						postCode: values.postCode,
						birthdate: formatDate(values.birthdate),
						accountId: values.accountId.replace(/ /g, ''),
					},
					token: userData.token,
				});

				setResponse({
					status: 'fetched',
					httpStatus: response.status,
					data: await response.json(),
				});
			} catch (error) {
				setResponse({
					status: 'error',
				});
			}
		},
	});

	useEffect(() => {
		analyticsService.trackPageView(pageNames.MORE_INFO);
	}, [analyticsService]);

	if (
		response.status === 'fetched' &&
		doesDataIncludePasswordResetUrl(response.data)
	) {
		analyticsService.trackPageView(pageNames.RESET_PASSWORD);
		return <ExternalRedirect to={response.data.passwordResetUrl} />;
	}

	if (
		response.status === 'fetched' &&
		doesDataHaveState(response.data) &&
		response.data.state === 'check_attempt_limit_reached'
	) {
		return <Redirect push={false} to="/retry-limit-reached" />;
	}

	return (
		<>
			<FormError formResponse={response} />

			<Card>
				<form noValidate onSubmit={formik.handleSubmit}>
					<Stack spaceBetween={4}>
						<TextField
							id="givenName"
							label="First name"
							fullWidth="always"
							required
							onChange={formik.handleChange}
							error={formik.errors.givenName}
							value={formik.values.givenName}
						/>

						<TextField
							id="familyName"
							label="Last name"
							fullWidth="always"
							required
							error={formik.errors.familyName}
							value={formik.values.familyName}
							onChange={formik.handleChange}
						/>

						<TextField
							id="postCode"
							label="Postcode"
							fullWidth="always"
							required
							error={formik.errors.postCode}
							value={formik.values.postCode}
							onChange={formik.handleChange}
						/>

						<DateField
							id="birthdate"
							label="Date of birth"
							fullWidth="always"
							error={formik.errors.birthdate}
							value={formik.values.birthdate}
							onChange={(values) => {
								formik.setValues({ ...formik.values, birthdate: values });
							}}
						/>

						<TextField
							id="accountId"
							label="OVO account number"
							fullWidth="always"
							hint="You’ll find this on any letter or email from OVO."
							required
							error={formik.errors.accountId}
							value={formik.values.accountId}
							onChange={formik.handleChange}
						/>
						<FindAccountNumberLink />

						<div className="flex-row--vertically-centred">
							<Margin right={3}>
								<PrimaryCTAButton
									disabled={response.status === 'loading'}
									type="submit"
								>
									Submit
								</PrimaryCTAButton>{' '}
							</Margin>
							{response.status === 'loading' ? <Loading /> : undefined}
						</div>
					</Stack>
				</form>
			</Card>
		</>
	);
};

function getErrorMessage(
	formResponse: Extract<FormResponse, { status: 'fetched' | 'error' }>
): JSX.Element {
	const genericMessage = (
		<P>
			Something went wrong, please try again later. If that still doesn't work,{' '}
			<ContactUsButton />
		</P>
	);

	if (formResponse.status === 'error') {
		return genericMessage;
	}

	if (
		formResponse.httpStatus === 400 &&
		doesDataHaveErrorArray(formResponse.data) &&
		formResponse.data.errors.includes('Invalid token')
	) {
		return <P>Your account activation link is not valid</P>;
	}

	if (
		formResponse.httpStatus !== 200 ||
		!doesDataHaveState(formResponse.data)
	) {
		return genericMessage;
	}

	if (
		matchesState(
			'email_confirmed.details.invalid_details',
			formResponse.data.state
		)
	) {
		return (
			<P>
				Sorry! Some of your details don’t match your account information. Please
				check and try again. If that still doesn't work, <ContactUsButton />
			</P>
		);
	}

	if (formResponse.data.state === 'token_expired') {
		return <P>Your account activation link has expired</P>;
	}

	return genericMessage;
}

function FormError({ formResponse }: { formResponse: FormResponse }) {
	const ref = useRef<null | HTMLDivElement>(null);
	const [errorMessage, setErrorMessage] = useState<null | JSX.Element>(null);

	useEffect(() => {
		if (formResponse.status === 'error' || formResponse.status === 'fetched') {
			setErrorMessage(getErrorMessage(formResponse));
		}
	}, [formResponse]);

	useEffect(() => {
		ref.current?.focus();
	}, [errorMessage]);

	if (!errorMessage) {
		return null;
	}

	return (
		<ErrorNotification id="error-notification" ref={ref}>
			{errorMessage}
		</ErrorNotification>
	);
}

function ExternalRedirect({ to }: { to: string }) {
	useEffect(() => {
		window.location.assign(to);
	}, [to]);

	// Spinner will show while the browser keeps the current page around (this can be quite a while on
	// slower connections)
	return <Loading />;
}
