import { Card, Heading2, P } from '@ovotech/nebula';
import React, { useEffect } from 'react';
import { pageNames } from '../../services/analytics';
import { usePageContext } from '../../components/Page';

export function CheckEmailCard() {
	const { analyticsService } = usePageContext();

	useEffect(() => {
		analyticsService.trackPageView(pageNames.CHECK_INBOX);
	}, [analyticsService]);

	return (
		<>
			<Heading2 className="heading">Time to check your inbox</Heading2>
			<Card>
				<P>
					We’ve sent you a link to confirm your details and set up your
					password.
				</P>
				<P>
					For security reasons, the link expires after 6 hours – but you can
					always start again later.
				</P>
			</Card>
		</>
	);
}
