export interface ApiClient {
	checkEmail: (body: CheckEmailBody) => Promise<Response>;
	confirmEmail: (body: ConfirmEmailBody) => Promise<Response>;
	checkDetails: (body: CheckDetailsBody) => Promise<Response>;
}

export interface CheckDetailsBody {
	token: string | null;
	memberDetails: {
		givenName: string;
		familyName: string;
		postCode: string;
		birthdate: string;
		accountId: string;
	};
}

interface CheckEmailBody {
	email: string;
	mixpanelDistinctId: string | null;
}

interface ConfirmEmailBody {
	token: string;
}

export function createApiClient({
	fetchClient,
	apiHost,
}: {
	fetchClient: WindowOrWorkerGlobalScope['fetch'];
	apiHost: string;
}): ApiClient {
	function urlFor(endpoint: string): string {
		const url = new URL(apiHost);
		url.pathname = endpoint;
		return url.toString();
	}

	function postJSONConfig({
		body,
		headers,
	}: {
		body: any;
		headers?: Record<string, string>;
	}): {
		method: string;
		headers: Record<string, string>;
		body: string;
	} {
		return {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...headers,
			},
			body: JSON.stringify(body),
		};
	}

	return {
		checkEmail(body: CheckEmailBody): Promise<Response> {
			return fetchClient(urlFor('/api/check_email'), postJSONConfig({ body }));
		},
		confirmEmail(body: ConfirmEmailBody): Promise<Response> {
			return fetchClient(
				urlFor('/api/confirm_email'),
				postJSONConfig({ body })
			);
		},
		checkDetails(body: CheckDetailsBody): Promise<Response> {
			return fetchClient(
				urlFor('/api/check_details'),
				postJSONConfig({ body })
			);
		},
	};
}
