import { createTheme, Heading1 } from '@ovotech/nebula';
import { AdditionalConfig, themeConfig } from '@ovotech/ovoenergy-theme';

import '@ovotech/ovoenergy-theme/dist/global.css';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './App.css';
import { FinalBillPrompt } from './components/FinalBillPrompt';
import { Footer } from './components/Footer/Footer';
import { Page } from './components/Page';
import { ConfirmEmailPage } from './pages/ConfirmEmailPage/ConfirmEmailPage';
import { EmailPage } from './pages/EmailPage/EmailPage';
import { MemberDetailsSwitch } from './pages/MemberDetailsSwitch';
import { RetryLimitReachedPage } from './pages/RetryLimitReachedPage';

// @ts-ignore
const theme = createTheme<AdditionalConfig>(themeConfig);

function App() {
	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<Page>
					<Switch>
						<Route path="/confirm-email">
							<EmailPage />
						</Route>

						<Route
							path="/details/:token"
							render={(props) => {
								return <ConfirmEmailPage token={props.match.params.token} />;
							}}
						/>

						<Route path="/final_bill_prompt">
							<FinalBillPrompt />
						</Route>

						<Route path="/details_form">
							<MemberDetailsSwitch />
						</Route>

						<Route path="/retry-limit-reached">
							<RetryLimitReachedPage />
						</Route>

						<Route>
							<div style={{ textAlign: 'center', padding: '40px 0 0' }}>
								<Heading1>Page not found :(</Heading1>
							</div>
						</Route>
					</Switch>
					<Footer />
				</Page>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
