import {
	Margin,
	PrimaryCTAButton,
	Stack,
	TextField,
	TextLink,
} from '@ovotech/nebula';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../components/common/Button.module.css';
import stylesLink from '../../components/common/Link.module.css';
import { FindAccountNumberLink } from '../../components/FindAccountNumberLink/FindAccountNumberLink';
import { Loading } from '../../components/Loading/Loading';
import { MemberDetails } from './MemberDetailsPage';
import { checkForEmptyString, validateAccountId } from './ValidationUtils';

export const AccountID = ({
	details,
	setDetails,
	prevPath,
	isLoading,
	submitCallback,
}: {
	details: MemberDetails;
	setDetails: React.Dispatch<React.SetStateAction<MemberDetails>>;
	prevPath: string;
	isLoading: boolean;
	submitCallback: () => void;
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		ref.current?.focus();
	}, []);

	function onChange(value: string) {
		const err = checkForEmptyString(value) || validateAccountId(value);
		setDetails({ ...details, accountId: { val: value, error: err } });
	}

	return (
		<Stack spaceBetween={4}>
			<div>
				&lt;{' '}
				<TextLink as={Link} to={prevPath} className={styles['btn-link']}>
					Back
				</TextLink>
			</div>
			<TextField
				id="accountID"
				label="What is your OVO account ID?"
				fullWidth="always"
				ref={ref}
				error={details.accountId.error}
				value={details.accountId.val}
				onChange={(e: React.ChangeEvent<any>) => onChange(e.target.value)}
			/>
			<FindAccountNumberLink />
			<div className="flex-row--vertically-centred">
				<Margin right={3}>
					<PrimaryCTAButton
						className={stylesLink['cta-link']}
						disabled={details.accountId.error || isLoading}
						type="submit"
						onClick={() => submitCallback()}
					>
						Submit
					</PrimaryCTAButton>
				</Margin>
				{isLoading ? <Loading /> : undefined}
			</div>
		</Stack>
	);
};
