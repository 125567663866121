import { DateFormat } from '@ovotech/nebula/dist/components/DateField/DateField';

export const EMPTY_ERROR = '';
export const MANDATORY_FIELD_ERROR = 'This field is mandatory';
export const BIRTHDATE_ERROR = 'Please enter a valid date';
export const ACCOUNT_ID_ERROR = 'Enter numbers only, for example: 90001234';

export function checkForEmptyString(value: string): string {
	return value && (value as string).length > 0
		? EMPTY_ERROR
		: MANDATORY_FIELD_ERROR;
}

export function checkForEmptyDate(date: DateFormat): string {
	return date &&
		date.day.length > 0 &&
		date.month.length > 0 &&
		date.year.length > 0
		? EMPTY_ERROR
		: MANDATORY_FIELD_ERROR;
}

export function validateDate(date: DateFormat): string {
	// V8 will accept 2 digit years whereas gecko says its invalid
	return date.year.length === 4 && !isNaN(Number(new Date(formatDate(date))))
		? EMPTY_ERROR
		: BIRTHDATE_ERROR;
}

export function formatDate({ day, month, year }: DateFormat): string {
	return `${year}-${ensureLeadingZero(month)}-${ensureLeadingZero(day)}`;
}

function ensureLeadingZero(value: string): string {
	return value.padStart(2, '0');
}

export function validateAccountId(accountId: string): string {
	return !isNaN(Number(accountId.replace(/ /g, '')))
		? EMPTY_ERROR
		: ACCOUNT_ID_ERROR;
}
