import styles from './Loading.module.css';

interface LoadingProps {
	size?: 'sm' | 'md' | 'lg';
	label?: string;
}

export const Loading = ({ size = 'sm' }: LoadingProps) => {
	const sizeClassKey = `loading__circle--${size}`;
	const classNames = `${styles.loading__circle} ${styles[sizeClassKey]}`;
	return (
		<>
			<div aria-label="Loading" className={classNames}>
				<svg viewBox="0 0 50 50" className={styles.loading__circle__svg}>
					<circle
						className={styles.loading__circle__svg__circle}
						cx="25"
						cy="25"
						r="20"
						fill="none"
						stroke="rgb(13, 132, 38)"
					/>
				</svg>
			</div>
		</>
	);
};
