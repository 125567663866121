import { useEffect, useState } from 'react';
import { usePageContext } from '../components/Page';
import { MemberDetailsPage as MemberDetailsPageNew } from './MemberDetailsPageV2/MemberDetailsPage';
import { MemberDetailsPage } from './MemberDetailsPage/MemberDetailsPage';
import {
	OptimizelyExperiment,
	OptimizelyVariation,
} from '@optimizely/react-sdk';

export const MemberDetailsSwitch = () => {
	const { analyticsService } = usePageContext();
	const [canTrack, setCanTrack] = useState<boolean>(false);

	useEffect(() => {
		setCanTrack(analyticsService.canTrack);
	}, [analyticsService, setCanTrack]);

	return (
		<OptimizelyExperiment
			experiment="one_question_at_a_time_journey_experiment"
			timeout={3000}
		>
			{canTrack ? (
				<OptimizelyVariation variation="on">
					<MemberDetailsPageNew />
				</OptimizelyVariation>
			) : (
				''
			)}

			<OptimizelyVariation default>
				<MemberDetailsPage />
			</OptimizelyVariation>
		</OptimizelyExperiment>
	);
};
