import React from 'react';
import { MemberDetailsForm } from './MemberDetailsForm';
import { Card, Heading1, P, Stack } from '@ovotech/nebula';

export const MemberDetailsPage = () => {
	return (
		<Stack spaceBetween={2}>
			<Heading1 className="heading">We need more information from you</Heading1>
			<Card>
				<P>
					Setting up your new online account? We just need a few more details
					from you. Please make sure they match your account information.
				</P>
			</Card>
			<MemberDetailsForm />
		</Stack>
	);
};
