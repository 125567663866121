import React, { useState } from 'react';
import { P, Modal, TextLink } from '@ovotech/nebula';
import { usePageContext } from '../Page';
import { buttonNames } from '../../services/analytics';
import styles from '../common/Button.module.css';

export function ContactUsButton() {
	const [showModal, setShowModal] = useState<boolean>(false);
	const { analyticsService } = usePageContext();

	function onClick() {
		analyticsService.trackButtonClick(buttonNames.CONTACT_US);
		setShowModal(true);
	}

	return (
		<>
			<TextLink as="button" className={styles['btn-link']} onClick={onClick}>
				give us a call.
			</TextLink>
			<Modal
				title="Contact us"
				isOpen={showModal}
				onClose={() => setShowModal(false)}
			>
				<P>
					Call us on: <TextLink href="tel:03303035063">0330 303 5063</TextLink>
				</P>
				<P>Our normal opening hours are Mon-Fri 9am-5pm</P>
			</Modal>
		</>
	);
}
