export type HttpResponse =
	| { status: 'idle' }
	| { status: 'loading' }
	| { status: 'fetched'; httpStatus: number; data: unknown }
	| { status: 'error' };

export function isObject(data: unknown) {
	return typeof data === 'object' && data != null;
}

export function doesDataIncludePasswordResetUrl(data: unknown) {
	return (
		isObject(data) &&
		// @ts-ignore
		'passwordResetUrl' in data &&
		typeof (data as any).passwordResetUrl === 'string'
	);
}

export function doesDataHaveErrorArray(data: unknown) {
	return isObject(data) && Array.isArray((data as any).errors);
}

export function doesDataHaveState(data: unknown) {
	// @ts-ignore
	return isObject(data) && 'state' in data;
}
