import React, { useState, useEffect } from 'react';
import { CheckEmailCard } from './CheckEmailCard';
import { EmailForm } from './EmailForm';
import { pageNames } from '../../services/analytics';
import { usePageContext } from '../../components/Page';
import { ErrorBanner } from './ErrorBanner';

const isJourneyDisabled = process.env.REACT_APP_DISABLE_JOURNEY === 'true';

export const EmailPage = () => {
	const { analyticsService } = usePageContext();
	useEffect(() => {
		analyticsService.trackPageView(pageNames.GET_STARTED);
	}, [analyticsService]);

	const [hasSubmitted, setSubmitted] = useState(false);

	return (
		<>
			{isJourneyDisabled ? (
				<ErrorBanner />
			) : hasSubmitted ? (
				<CheckEmailCard />
			) : (
				<EmailForm setSubmitted={setSubmitted} />
			)}
		</>
	);
};
